import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, Button, Fade, useTheme, useMediaQuery } from '@material-ui/core'
import { H1, MarkdownHandler } from '@system'
import classNames from 'classnames'
import renderBloks from '@renderBloks'
import renderBlok from '@renderBlok'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'
import Icon from '@system/icon'

const useStyles = makeStyles((theme) => ({
  quizContainer: {
    height: `calc(100vh - ${theme.navHeight.noSubNav}px)`, // TODO: update with nav height var when implemented
    padding: 0,
    maxHeight: 799,
    [theme.breakpoints.down('md')]: {
      height: 'unset',
      maxHeight: 'unset',
    },
  },
  frameContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  titleAndTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '3rem 2rem 3rem 1rem',
    flexGrow: 1,
    flexBasis: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '3rem 0',
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  description: {
    paddingTop: '30px',
    lineHeight: '30px',
    color: theme.palette.text.tertiary,
  },
  startButton: {
    marginTop: '30px',
    paddingRight: '.3rem',
    color: theme.palette.text.white,
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    '&:hover': {
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      backgroundColor: theme.palette.primary.main,
    },
  },
  imageContainer: {
    width: '45%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  counterStyles: {
    display: 'none',
    position: 'fixed',
    top: theme.navHeight.noSubNav,
    left: 0,
    marginLeft: 'auto',
    right: 0,
    marginRight: 'auto',
    backgroundColor: theme.palette.primary.main,
    padding: '.4rem 2rem',
    fontSize: '1.3rem',
    width: '8rem',
    textAlign: 'center',
  },
  counterDisplay: {
    display: 'initial',
  },
  formContainer: {
    height: '100%',
    flexGrow: 1,
    flexBasis: 0,
  },
  formFrame: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  formTitleTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexGrow: 1,
    flexBasis: 0,
    padding: '3rem 2rem 3rem 1rem',
    [theme.breakpoints.down('sm')]: {
      padding: '3rem 1rem 3rem 0',
    },
  },
  navButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'fit-content',
    marginTop: '2rem',
  },
  navButtonText: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  backButton: {
    color: theme.palette.primary.main,
  },
  chevronLeft: {
    marginRight: '0.3rem',
  },
  chevronRight: {
    marginLeft: '0.3rem',
  },
}))

const InteractiveQuizModule = ({ blok }) => {
  const {
    title,
    subtitle,
    description,
    quizStartButtonText,
    titleFrameImage,
    ofString,
    questions,
    formTitle,
    formText,
    marketoForm,
    downloadCta,
    resultFrameImage,
  } = blok
  let results = [
    blok.resultOne,
    blok.resultTwo,
    blok.resultThree,
    blok.resultFour,
    blok.resultFive,
  ]
  results = results.filter((result) => result && result.length)
  const [activeStep, setActiveStep] = useState(0)
  const [scores, setScores] = useState(new Array(questions.length).fill(null))

  const quizScore = Math.ceil(
    // scales score vs # of results input
    (scores.reduce((ac, vl) => (ac += vl)) / scores.length) *
      (results.length / 5)
  )

  const classes = useStyles(blok)
  const theme = useTheme()
  const imageStyles = {
    width: '100%',
    minWidth: '100%',
    objectFit: 'contain',
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const updateScores = (position, score) => {
    let newScores = [...scores]
    newScores[position - 1] = score
    setScores(newScores)
  }

  const titleFrame = () => (
    <Fade timeout={500} in={activeStep === 0}>
      <PageContainer>
        <ContentContainer
          disableGutters
          noRight={useMediaQuery(theme.breakpoints.up('md'))}
        >
          <Box className={classes.frameContainer}>
            <Box className={classes.titleAndTextContainer}>
              <H1 component="h2" color="primary" className={classes.title}>
                {title}
              </H1>
              <H1 color="primary" component="div">
                {subtitle}
              </H1>
              <MarkdownHandler className={classes.description}>
                {description}
              </MarkdownHandler>
              <Box className={classes.startButtonContainer}>
                <Button
                  variant="contained"
                  onClick={() => handleNext(0)}
                  className={classes.startButton}
                >
                  {quizStartButtonText}
                  <Icon styles={classes.chevronRight}>ChevronRight</Icon>
                </Button>
              </Box>
            </Box>
            <Box className={classes.imageContainer}>
              {renderBloks(titleFrameImage, { style: imageStyles })}
            </Box>
          </Box>
        </ContentContainer>
      </PageContainer>
    </Fade>
  )

  const formFrame = () => (
    <Fade timeout={500} in={activeStep === questions.length + 1}>
      <PageContainer>
        <ContentContainer
          disableGutters
          noRight={useMediaQuery(theme.breakpoints.up('md'))}
        >
          <Box
            className={classNames(classes.frameContainer, classes.formFrame)}
          >
            <Box className={classes.formTitleTextContainer}>
              <H1 component="h2" color="primary">
                {formTitle}
              </H1>
              <MarkdownHandler className={classes.description}>
                {formText}
              </MarkdownHandler>
              <Box className={classes.navButtonContainer}>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => handleBack(questions.length + 1)}
                  className={classNames(classes.navButton, classes.backButton)}
                >
                  <Icon styles={classes.chevronLeft}>ChevronLeft</Icon>
                  <span className={classes.navButtonText}>Back</span>
                </Button>
              </Box>
            </Box>
            <Box className={classes.formContainer}>
              {renderBloks(marketoForm, {
                isQuiz: true,
                quizCallback: handleNext,
              })}
            </Box>
          </Box>
        </ContentContainer>
      </PageContainer>
    </Fade>
  )

  const resultFrame = () => (
    <Fade timeout={500} in={activeStep === questions.length + 2}>
      <PageContainer>
        <ContentContainer
          disableGutters
          noRight={useMediaQuery(theme.breakpoints.up('md'))}
        >
          <Box className={classes.frameContainer}>
            {renderBloks(results[quizScore - 1], { downloadCta })}
            <Box className={classes.imageContainer}>
              {renderBloks(resultFrameImage, { style: imageStyles })}
            </Box>
          </Box>
        </ContentContainer>
      </PageContainer>
    </Fade>
  )

  const frames = [
    titleFrame(blok),
    ...questions.map((q, i) =>
      renderBlok(q, {
        activeStep,
        handleBack,
        handleNext,
        updateScores,
        title,
        subtitle,
        position: i + 1,
        prevSelected: scores[i],
      })
    ),
    formFrame(blok, classes),
    resultFrame(blok, classes),
  ]

  return (
    <SbEditable content={blok}>
      <PageContainer>
        <Box className={classes.quizContainer}>
          <Box
            className={classNames(classes.counterStyles, {
              [classes.counterDisplay]:
                activeStep > 0 && activeStep < frames.length - 2,
            })}
          >
            {activeStep} {ofString || 'of'} {frames.length - 3}
          </Box>
          {frames[activeStep]}
        </Box>
      </PageContainer>
    </SbEditable>
  )
}

export default InteractiveQuizModule
